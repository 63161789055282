body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body, input, textarea, button {
  font-family: "Microsoft YaHei UI", "Microsoft YaHei", sans-serif;
}

html, body {
  background-color: #f6f6f6;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  transition: 0.1s;
  color: #0099ff;
}
